<template>
  <z-plate has-bottom-border>
    <template #titleLeft>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first"></el-tab-pane>
        <el-tab-pane label="修改密码" name="second"></el-tab-pane>
      </el-tabs>
    </template>

    <!-- 基本信息 -->
    <div class="first" v-if="activeName === 'first'">
      <el-form ref="firstForm" :model="firstForm" label-width="100px">
        <el-form-item label="教育ID：">
          <strong>{{teachId}}</strong>
        </el-form-item>

        <el-form-item label="教师姓名：">{{name}}</el-form-item>

        <el-form-item label="所属单位：">{{schoolName}}</el-form-item>

        <el-form-item
          prop="phone"
          label="手机号码："
          :rules="{ required: true, message: '请输入手机号码', trigger: 'blur' }"
        >
          <el-input v-model="firstForm.phone" placeholder="请输入手机号码" style="width: 360px" />
        </el-form-item>

        <el-form-item label="邮箱：">
          <el-input v-model="firstForm.email" placeholder="请输入邮箱" style="width: 360px" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBase">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 修改密码 -->
    <div class="second" v-else>
      <el-form ref="secondForm" :model="secondForm" label-width="120px">
        <el-form-item
          prop="password"
          label="原密码："
          required
        >
          <el-input v-model.trim="secondForm.password" placeholder="请输入原密码" style="width: 360px" />
        </el-form-item>

        <el-form-item
          prop="newPassword"
          label="新密码："
          required
        >
          <el-input v-model.trim="secondForm.newPassword" placeholder="请输入新密码" style="width: 360px" />
        </el-form-item>

        <el-form-item
          prop="confirmNewPassword"
          label="确认密码："
          required
        >
          <el-input
            v-model.trim="secondForm.confirmNewPassword"
            placeholder="确认密码和新密码保持一致"
            style="width: 360px"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="savePassowrd">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </z-plate>
</template>

<script>
export default {
  data() {
    return {
    	teachId: "", //教育id
    	name: "", //教师姓名
    	schoolName: "", //所属单位
      activeName: "first",
      firstForm: {
        phone: "",
        email: ""
      },
      secondForm: {
        password: "",
        newPassword: "",
        confirmNewPassword: ""
      },

    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.$post('/findTeacherInfo', {}).then((m) => {
				this.firstForm.phone = m.content.teacherInfo.telphone;
				this.firstForm.email = m.content.teacherInfo.mail;
				this.teachId = m.content.teacherInfo.eduId;
				this.name = m.content.teacherInfo.name;
				this.schoolName = m.content.teacherInfo.schoolName;
			});
    },
    handleClick() {},

    saveBase() { //基本信息保存
      this.$refs.firstForm.validate(valid => {
        if (valid) {
        	let reg_phone = /^1[3-9]{1}[0-9]{9}$/; //手机
        	let reg_mail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        	if(!reg_phone.test(this.firstForm.phone)) {
						return this.$message({
							showClose: true,
	            type: 'error',
	            message: '手机号码格式不正确！'
	          });
					}
        	if(!!this.firstForm.email && !reg_mail.test(this.firstForm.email)) {
        		return this.$message({
        			showClose: true,
              type: 'error',
              message: '邮箱格式不正确！'
          	});
        	}
        	
        	this.$post('/updateTeacherInfo', {"telphone": this.firstForm.phone, "mail": this.firstForm.email}).then((m) => {
						if(m.resultCode == -1) {
							this.$message({
								showClose: true,
	              type: 'error',
	              message: m.resultDesc
	          	});
	          	return false;
						}else if(m.resultCode == 0) {
							this.$message({
								showClose: true,
	              type: 'success',
	              message: "保存成功！"
	          	});
						}
					});
        } else {
          return false;
        }
      });
    },

    savePassowrd() {
      if(!!!this.secondForm.password) {
				this.$message({
					showClose: true,
          type: 'error',
          message: '请输入原密码！'
        });
        return false;
			}
			if(!!!this.secondForm.newPassword) {
				this.$message({
					showClose: true,
          type: 'error',
          message: '请输入新密码！'
        });
        return false;
			}
			if(!!!this.secondForm.confirmNewPassword) {
				this.$message({
					showClose: true,
          type: 'error',
          message: '请输入确认密码！'
        });
        return false;
			}
			if(this.secondForm.newPassword != this.secondForm.confirmNewPassword) {
				this.$message({
					showClose: true,
          type: 'error',
          message: '确认密码和新密码不一致！'
        });
        return false;
			}
			this.$post('/updatePassword', {"oldpwd": this.secondForm.password, "newpwd": this.secondForm.newPassword}).then((m) => {
				if(m.resultCode == -1) {
					this.$message({
						showClose: true,
            type: 'error',
            message: m.resultDesc
        	});
        	return false;
				}else if(m.resultCode == 0) {
					this.$message({
						showClose: true,
            type: 'success',
            message: "密码修改成功！"
        	});
				}
			});
    }
  }
};
</script>

<style lang="scss" scoped>
.first,
.second {
  padding: 50px 44px 100px;
}
</style>